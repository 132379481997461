import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { RibbonComponent } from './components/ribbon/ribbon.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RibbonComponent],
  template: `
  <router-outlet></router-outlet>
  <app-ribbon></app-ribbon>
`,
})
export class AppComponent {
}

import { Routes } from '@angular/router';

import { authGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: 'auth/login',
    loadComponent: () => import('./auth/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: 'auth/forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'prefix',
  },
  {
    path: 'app',
    loadComponent: () => import('./pages/main-app/main-app.component').then((m) => m.MainAppComponent),
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./components/dashboard/dashboard.component').then((m) => m.DashboardComponent),
      },
      // {
      //   path: 'billing-details/:status',
      //   loadComponent: () => import('./components/bill-list/bill-list.component').then((m) => m.BillListComponent),
      // },
      {
        path: 'orders/:status',
        loadComponent: () => import('./components/order-list/order-list.component').then((m) => m.OrderListComponent),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'prefix',
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: 'bill-detail/:orderId',
    loadComponent: () => import('./pages/bill-details/bill-details.component').then((m) => m.BillDetailsComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/patient-demography/patient-demography.component').then(
            (c) => c.PatientDemographyComponent
          ),
      },
      {
        path: 'patient-encounter-details',
        loadComponent: () =>
          import('./components/encounter-details/encounter-details.component').then((c) => c.EncounterDetailsComponent),
      },
      {
        path: 'patient-soap',
        loadComponent: () => import('./components/soap/soap.component').then((c) => c.SoapComponent),
      },
      {
        path: 'patient-super-bill',
        loadComponent: () => import('./components/super-bill/super-bill.component').then((c) => c.SuperBillComponent),
      },
      {
        path: 'patient-documents',
        loadComponent: () => import('./components/documents/documents.component').then((c) => c.DocumentsComponent),
      },
      {
        path: 'charges',
        loadComponent: () => import('./components/charges/charges.component').then((c) => c.ChargesComponent),
      },
      {
        path: 'bill-detail-section',
        loadComponent: () =>
          import('./components/bill-detail-section/bill-detail-section.component').then(
            (c) => c.BillDetailSectionComponent
          ),
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: 'helpdesk',
    loadComponent: () => import('./pages/helpdesk/helpdesk.component').then((c) => c.HelpdeskComponent),
    canActivate: [authGuard],
  },
  {
    path: 'preview',
    loadComponent: () => import('./pages/preview/preview.component').then((c) => c.PreviewComponent),
    canActivate: [authGuard],
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.component').then((c) => c.ProfileComponent),
    children: [
      {
        path: 'info',
        loadComponent: () => import('./components/profile/info/info.component').then((c) => c.InfoComponent),
      },
      {
        path: 'security',
        loadComponent: () =>
          import('./components/profile/security/security.component').then((c) => c.SecurityComponent),
      },
      { path: '', redirectTo: 'info', pathMatch: 'prefix' },
    ],
    canActivate: [authGuard],
  },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
];

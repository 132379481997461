{
  "dev": {
    "config": {
      "apiKey": "AIzaSyC0TTta5egRJ0LxDSeEvd8BhLrGG9IPQRk",
      "authDomain": "mlc-dev.firebaseapp.com",
      "databaseURL": "https://mlc-dev.firebaseio.com",
      "projectId": "mlc-dev",
      "storageBucket": "mlc-dev.appspot.com",
      "messagingSenderId": "526507237982"
    },
    "biller": {
      "appId": "1:526507237982:web:23202308cd0e9c38",
      "measurementId": "G-2PFG1WKBCM"
    }
  },
  "demo": {
    "config": {
      "apiKey": "AIzaSyBcQsp_6jN0pOs7HaoUZZeYvIf3zesOd2I",
      "authDomain": "mlc-demo.firebaseapp.com",
      "databaseURL": "https://mlc-demo-default-rtdb.firebaseio.com",
      "projectId": "mlc-demo",
      "storageBucket": "mlc-demo.appspot.com",
      "messagingSenderId": "1092256480351"
    },
    "biller": {
      "appId": "1:1092256480351:web:96124ea469975a8dc4afea",
      "measurementId": "G-6P6N28JMTC"
    }
  },
  "production": {
    "config": {
      "apiKey": "AIzaSyBc_bgXKqLhW6O4a2Ep2XAV2uYJ-IxLKAA",
      "authDomain": "prod-mlc.firebaseapp.com",
      "databaseURL": "https://prod-mlc-default-rtdb.firebaseio.com",
      "projectId": "prod-mlc",
      "storageBucket": "prod-mlc.appspot.com",
      "messagingSenderId": "964636746264"
    },
    "biller": {
      "appId": "1:964636746264:web:313411acdefae2e5ee196d",
      "measurementId": "G-GGY89B8311"
    }
  }
}
